export const sidebarData = [
  {
    id: 1,
    title: "Main",
    listItems: [
      {
        id: 1,
        title: "Home",
        url: "/",
        icon: "home",
      },
      {
        id: 2,
        title: "Events",
        url: "/pages/events/",
        icon: "event",
      },
    ],
  },
  {
    id: 2,
    title: "General",
    listItems: [
      {
        id: 1,
        title: "Users",
        url: "#",
        icon: "group",
      },
      {
        id: 2,
        title: "Calendar",
        url: "/",
        icon: "calendar_month",
      },
    ],
  },
  {
    id: 3,
    title: "Management",
    listItems: [
      {
        id: 1,
        title: "Reports",
        url: "#",
        icon: "summarize",
      },
    ],
  },
  {
    id: 4,
    title: "Settings",
    listItems: [
      {
        id: 1,
        title: "Settings",
        url: "/",
        icon: "settings",
      },
      {
        id: 2,
        title: "Logout",
        url: "/",
        icon: "power_settings_new",
      },
    ],
  },
];

