import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";
import truncate from "truncate-html";
import Grid from "@mui/material/Grid";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: "short", day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
};
const imageUrl = (url) => {
  return process.env.REACT_APP_BASE_IMAGE_URL + url;
};
const galleryImageUrl = (image) => {
  return `${process.env.REACT_APP_BASE_IMAGE_URL}gallery/img_500_${image}`;
};
const EventDetails = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [images, setImages] = useState([]);

  const truncateHtml = (htmlContent, length = 100) => {
    return truncate(htmlContent, {
      length: length,
      ellipsis: "...",
      keepImageTag: true,
    });
  };
  const fetchEventDetails = async () => {
    Axios.get(`?type=event&id=${eventId}`)
      .then((response) => {
        setEvent(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };
  const getEventMedia = () => {
    Axios.get(`?type=eventMedia&event_id=${eventId}`)
      .then((response) => {
        setImages(response.data || []); // Ensure to access the media array from the response
        console.log(images);
      })
      .catch((error) => {
        console.error("Error fetching event media:", error);
      });
  };

  useEffect(() => {
    fetchEventDetails();
    getEventMedia();
  }, [eventId]);

  if (!event) {
    return (
      <MKBox
        minHeight="70vh"
        width="100%"
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKTypography variant="h4">Loading...</MKTypography>
      </MKBox>
    );
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        relative
        center
      />
      <img
        src={imageUrl(event.image)}
        alt={event.title}
        className="md:w-2/5 w-fit sm:hidden block "
      />
      <div className="bg-gray-100 ">
        <Container>
          <div className="flex flex-col-reverse md:flex-row justify-between">
            <div className="flex flex-col p-6 md:p-0 justify-center">
              <p className="text-3xl md:text-5xl font-bold text-black mb-4">{event.title}</p>
              <MKTypography variant="body2" gutterBottom>
                Date: {formatDate(event.date)}
              </MKTypography>
              <MKTypography variant="body2" gutterBottom>
                Time: 4pm until Late {/* {formatTime(event.date)} */}
              </MKTypography>
              <MKTypography variant="body2" gutterBottom>
                
              </MKTypography>
              <div>
              <button type="button" class="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-purple-400 font-medium rounded-lg text-sm px-16 py-3 me-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">
              {event.location}
              </button>
                {event?.date ? (
                  new Date(event.date) > new Date() ? (
                    
                    <MKButton
                      variant="gradient"
                      color="info"
                      size="large"
                      sx={{ py: 2, px: 2, mt: 2 }}
                    >
                      Ticket: {event.ticketPrice}&nbsp;
                      <Icon sx={{ ml: 6 }}>arrow_right</Icon>
                    </MKButton>
                  ) : (
                    <button
                      onClick={() => {
                        setShowDescription(!showDescription);
                      }}
                      type="button"
                      class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                    >
                      {showDescription ? "Show Gallery" : "Show Description"}
                    </button>
                  )
                ) : // Render nothing if the data is not loaded yet
                null}
              </div>
            </div>
            <img
              src={imageUrl(event.image)}
              alt={event.title}
              className="md:w-2/5 w-fit sm:block hidden"
            />
          </div>
        </Container>
      </div>
      <Container sx={{ mt: 5 }}>
        <div>
          {new Date(event?.date) > new Date() || showDescription ? (
            <MKBox mt={3}>
              <MKTypography variant="h6" gutterBottom>
                Event Details
              </MKTypography>
              <MKBox
                sx={{
                  "& p": {
                    marginBottom: 2,
                  },
                  "& img": {
                    maxWidth: "100%",
                    height: "auto",
                    marginBottom: 2,
                  },
                  "& h2, & h3, & h4, & h5, & h6": {
                    marginTop: 3,
                    marginBottom: 1,
                  },
                  "& ul, & ol": {
                    marginLeft: 4,
                    marginBottom: 2,
                  },
                }}
                className="text-gray-800 font-light"
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </MKBox>
          ) : (
            ""
          )}
          {event?.date && new Date(event?.date) < new Date() ? (
            images.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {/* Define four inner divs that always exist */}
                {[0, 1, 2, 3].map((col) => (
                  <div key={col} className="grid gap-4">
                    {images
                      .filter((_, index) => index % 4 === col) // Dynamically assign images to this column
                      .map((image, index) => (
                        <div key={image.id || index}>
                          <img
                            className="h-auto max-w-full rounded-lg"
                            src={galleryImageUrl(image.title)} // Generate the dynamic URL
                            alt={image.title} // Image alt text
                          />
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            ) : "No Images Found"
          ) : (
            ""
          )}
        </div>
      </Container>
      <MKBox pt={6} px={1} sx={{ mt: 5 }}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default EventDetails;
