import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    userName: null,
    userEmail: null,
    userLevel: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
            state.userEmail = action.payload.userEmail;
            state.userName =  action.payload.userName;
        },
        logout: (state) => {
            state.isAuthenticated = null;
            state.userEmail = null;
            state.userName = null;
        },
    },
});

export const {loginSuccess, logout} = authSlice.actions;
export default authSlice.reducer;