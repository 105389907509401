/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// axios to endpoint connection
import Axios from 'utils/Axios';

// react-router-dom components
import { Link, useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import {loginSuccess} from "../../../../redux/slices/auth/authSlice"
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/Event-page-hero-bg.jpg";

function SignInCover() {

  // redux dispatch and router dom navgate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //--- variable declarations ---
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasInput, setHasInput] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  //--- function to handle submit ---
  let handleLogin = async (e) => {
    e.preventDefault();

    //--- constructing the payload ---
    const data = JSON.stringify({
      type: "authenticate",
      email: email,
      password: password,
    });

    try {
      let res = await Axios.post("/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const data =  res.data;
        const userEmail = data.user['email'];
        const userName = data.user['name'];

        // redux data
        dispatch(loginSuccess({ userEmail, userName }));

        // storing the token and user info in local storage
        localStorage.setItem("token", data.jwtToken)
        localStorage.setItem("userInfo", JSON.stringify(data.user))

        navigate("/pages/dashboard/");
      }
    } catch (err) {
      alert("Error Please Contact Support");
      console.log(err);
    }
  };

  //--- Password View ----
  const handleHasInput = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    setHasInput(inputValue.length > 0);
  };

  const viewPassword = () => {
    setPasswordView(!passwordView);
  };

  console.log(localStorage.getItem("userInfo"), "AFTER");
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form" onSubmit={handleLogin}>
            <MKBox mb={2}>
              <MKInput
                type="email"
                variant="standard"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type={passwordView ? "text" : "password"}
                variant="standard"
                label="Password"
                onChange={handleHasInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {hasInput && (
                        <Icon sx={{ cursor: "pointer" }} onClick={viewPassword} color="inherit">
                          {passwordView ? "visibility_off" : "visibility"}
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                sign in
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign Up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default SignInCover;
