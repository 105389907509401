import React from "react";
import Nav from "layouts/dashboardNav";
import Sidebar from "layouts/dashboardSidebar";
import Footer from "layouts/dashboardFooter";
import EventSettings from "./EventSettings";

function EventSettingsLayout() {
  return (
    <>
      <div className="main">
        <div className="navContainer">
          <Nav />
        </div>
        <div className="main-container">
          <div className="sidebarContainer">
            <Sidebar />
          </div>
          <div className="contentContainer">
                <EventSettings />
          </div>
        </div>
        <div className="footerContainer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default EventSettingsLayout;
