export const chartBoxTotalEvents = {
  color: "#8884d8",
  icon: "calendar_month",
  title: "Total Events",
  number: "59",
  dataKey: "events",
  percentage: 45,
  chartData: [
    { name: "Sun", events: 4 },
    { name: "Mon", events: 12 },
    { name: "Tue", events: 6 },
    { name: "Wed", events: 24 },
    { name: "Thur", events: 5 },
    { name: "Fri", events: 8 },
    { name: "Sat", events: 1 },
  ],
};
export const chartBoxFutureEvents = {
  color: "#84d8b2",
  icon: "today",
  title: "Future Events",
  number: "5",
  dataKey: "events",
  percentage: -5,
  chartData: [
    { name: "Sun", events: 4 },
    { name: "Mon", events: 12 },
    { name: "Tue", events: 6 },
    { name: "Wed", events: 24 },
    { name: "Thur", events: 5 },
    { name: "Fri", events: 8 },
    { name: "Sat", events: 1 },
  ],
};
export const chartBoxPastEvents = {
  color: "#d4d884",
  icon: "event_available",
  title: "Previous Events",
  number: "33",
  dataKey: "events",
  percentage: 66,
  chartData: [
    { name: "Sun", events: 4 },
    { name: "Mon", events: 12 },
    { name: "Tue", events: 6 },
    { name: "Wed", events: 24 },
    { name: "Thur", events: 5 },
    { name: "Fri", events: 8 },
    { name: "Sat", events: 1 },
  ],
};

export const chartBoxSoonEvents = {
  color: "#427484",
  icon: "schedule",
  title: "Coming Soon Events",
  number: "2",
  dataKey: "events",
  percentage: 20,
  chartData: [
    { name: "Sun", events: 4 },
    { name: "Mon", events: 12 },
    { name: "Tue", events: 6 },
    { name: "Wed", events: 24 },
    { name: "Thur", events: 5 },
    { name: "Fri", events: 8 },
    { name: "Sat", events: 1 },
  ],
};

export const ticketSold = {
  title: "Tickets Sold",
  color: "#FF8042",
  dataKey: "profit",
  chartData: [
    { name: "Sun", profit: 99 },
    { name: "Mon", profit: 212 },
    { name: "Tue", profit: 96 },
    { name: "Wed", profit: 324 },
    { name: "Thur", profit: 445 },
    { name: "Fri", profit: 800 },
    { name: "Sat", profit: 331 },
  ],
};

export const ticketRevenue = {
  title: "Tickets Revenue",
  color: "#8884d8",
  dataKey: "revenue",
  chartData: [
    { name: "Sun", revenue: 15000 },
    { name: "Mon", revenue: 5000 },
    { name: "Tue", revenue: 45000 },
    { name: "Wed", revenue: 10000 },
    { name: "Thur", revenue: 13000 },
    { name: "Fri", revenue: 4000 },
    { name: "Sat", revenue: 75000 },
  ],
};
