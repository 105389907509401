import { configureStore } from "@reduxjs/toolkit";
import userInfoReducer from "./slices/user/userSlice";
import authReducer from "./slices/auth/authSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    userInfo: userInfoReducer,

  },
});

export default store;
