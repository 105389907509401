/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/Event-page-hero-bg.jpg";

import { useState } from "react";
import axios from "axios";

function Cover() {

  const navigate = useNavigate();
  //--- variable declarations ---
  const [names, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordView, setPasswordView] = useState(false);
  const [passwordView2, setPasswordView2] = useState(false);


  //--- function to handle submit ---
  const handleRegistration = (e) => {
    e.preventDefault();

    const data = JSON.stringify({
      type: "user",
      names: names,
      email: email,
      password: password,
      password2: password2,
    });

    axios
      .post(process.env.REACT_APP_BASE_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if(res.status === 200 && res.data['message'] === "created"){
          navigate("/authentication/sign-in/cover");
        }else{
          console.log("Error, Contact the Admin");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //--- Password View ----
  const viewPassword = () => {
    setPasswordView(!passwordView);
  };
  const viewPassword2 = () => {
    setPasswordView2(!passwordView2);
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form" onSubmit={handleRegistration}>
            <MKBox mb={2}>
              <MKInput
                type="text"
                variant="standard"
                label="Name"
                onChange={(e) => setNames(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="email"
                variant="standard"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type={passwordView ? "text" : "password"}
                variant="standard"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {password.length > 0 && (
                        <Icon sx={{ cursor: "pointer" }} onClick={viewPassword} color="inherit">
                          {passwordView ? "visibility_off" : "visibility"}
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type={passwordView2 ? "text" : "password"}
                variant="standard"
                label="Confirm Password"
                onChange={(e) => setPassword2(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {password2.length > 0 && (
                        <Icon sx={{ cursor: "pointer" }} onClick={viewPassword2} color="inherit">
                          {passwordView2 ? "visibility_off" : "visibility"}
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                Sign Up
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="dark">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-in/cover"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
