import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import truncate from "truncate-html";

const EventList = ({ events }) => {
  const navigate = useNavigate();
  const truncateHtml = (htmlContent, length = 100) => {
    return truncate(htmlContent, {
      length: length,
      ellipsis: "...",
      keepImageTag: true,
    });
  };

  const handleClickOpen = (eventId) => {
    navigate(`/event/${eventId}`);
  };
  return (
    <Container sx={{ mt: 5 }} >
      {events?.map((day, index) => (
        <MKBox key={index} mb={4}>
          <Grid container spacing={2} className="pl-5">
            <Grid item xs={12} md={3} container spacing={2} mt={2}>
              <MKTypography variant="h5" gutterBottom>
                {day.date}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={9} container spacing={2} className="">
              {day.events?.map((event, idx) => (
                <Grid container spacing={2} mt={2} key={idx}>
                  <Grid
                    item
                    xs={12} md={10}
                    onClick={() => handleClickOpen(event.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <MKTypography variant="h5">{event.title}</MKTypography>
                    <MKTypography variant="h6">{event.location}</MKTypography>
                    <MKTypography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: truncateHtml(event.description, 200),
                      }}
                    ></MKTypography>
                  </Grid>
                  <Grid item xs={12} md={2} style={{ textAlign: "right" }}>
                    <MKTypography variant="body2">{event.time}</MKTypography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <hr style={{ marginTop: "25px", fontSize: "1px", color: "rgba(200, 200, 200, 0.18)" }} />
        </MKBox>
      ))}
    </Container>
  );
};

EventList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          time: PropTypes.string.isRequired,
          image: PropTypes.string,
          location: PropTypes.string,
          ticketPrice: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default EventList;
