import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

//--- import its css ---
import "./pieChart.scss";
function PieChartBox() {
  const data = [
    //{ name: "Revenue", value: 3000, color: "#00C49F" },
    { name: "Users", value: 700, color: "#00C49F" },
    { name: "Tickets", value: 200, color: "#FF8042" },
    { name: "Sponsors", value: 40, color: "#0088FE" },
  ];

  return (
    <div className="pieChart">
      <h1>Leadss by Source</h1>
      <div className="chart">
        <ResponsiveContainer width="99%" height={300}>
          <PieChart>
            <Tooltip contentStyle={{ background: "white", borderRadius: "5px" }} />
            <Pie
              data={data}
              innerRadius={"70%"}
              outerRadius={"90%"}
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry) => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="options">
        {data.map((item) => (
          <div className="option" key={item.name}>
            <div className="title">
              <div className="dot" style={{ backgroundColor: item.color }} />
              <span>{item.name}</span>
            </div>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PieChartBox;