import React, { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// History page sections
import EventList from "pages/Home/sections/EventList";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";

// Images
import product5 from "assets/images/HanyeIcon.png";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const cities = [
    "Nairobi",
    "Mombasa",
    "Nukuru",
    "Ruiru",
    "Eldoret",
    "Kisumu",
    "Kikuyu",
    "Ngong",
];

function History() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchEvents = async (page = 1, limit = 10) => {
    Axios.get(`?type=event&event_time=past`)
      .then((response) => {
        const data = response.data;
        setTotalPages(data.length);
        setData(data);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };

  useEffect(() => {
    fetchEvents(page);
  }, [page, BASE_URL]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    // Logic to filter events by month
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    // Logic to filter events by city
  };

  const filteredCities = cities.filter((city) =>
    city.toLowerCase().includes(citySearch.toLowerCase())
  );

  return (
    <>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        transparent
        relative
        center
      /> */}
      <section className="bg-gray-100">
        <Container>
          <div className="flex flex-col md:flex-row items-center justify-between py-12 md:space-x-16 px-6">
            <div className="w-full md:w-1/4 flex items-center md:justify-start">
              <img src={product5} alt="Landing Image" />
            </div>
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h1 className="text-3xl md:text-5xl font-bold text-black mb-4">
                Past events on  <span className="text-purple-800">HanyeLeo</span>
              </h1>
              <p className="text-gray-700 mb-6">
                Misss an Event? Check out what you missed by viewing the image galleries of our previous events.  Be sure to come back to keep up to date with the hottest Events. 
              </p>
            </div>
          </div>
        </Container>
      </section>
      <div className="text-center my-16">
        <p className="text-3xl md:text-5xl font-bold text-gray-700 mb-4">Event History</p>
        <p className="font-light text-gray-700 md:px-28 text-center mb-4">
          Click on the Events Below to see Image Galleries of Previous Events{" "}
        </p>
      </div>
      <Container sx={{ mt: 5 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              style={{ backgroundColor: "white", height: "43px" }}
              inputProps={{ "aria-label": "Select Month" }}
              sx={{
                mr: 2,
                minWidth: 150,
                "& .MuiSelect-select": {
                  padding: "6px 16px",
                },
                "&.MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select Month</em>
              </MenuItem>
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Select
              value={selectedCity}
              onChange={handleCityChange}
              displayEmpty
              style={{ backgroundColor: "white", height: "43px" }}
              inputProps={{ "aria-label": "Select City" }}
              sx={{
                mr: 2,
                minWidth: 150,
                "& .MuiSelect-select": {
                  padding: "6px 16px",
                },
                "&.MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select City</em>
              </MenuItem>
              <MenuItem isReadonly>
                <TextField
                  value={citySearch}
                  onChange={(e) => setCitySearch(e.target.value)}
                  placeholder="Search City"
                  variant="outlined"
                  fullWidth
                />
              </MenuItem>
              {filteredCities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Container>
      <EventList events={data} />
      <Container sx={{ mt: 5 }}>
        <MKPagination count={totalPages} page={page} onChange={handlePageChange}>
          <MKPagination item onClick={() => handlePageChange(null, Math.max(1, page - 1))}>
            <Icon>keyboard_arrow_left</Icon>
          </MKPagination>
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <MKPagination
              item
              key={pageNumber + 1}
              onClick={() => handlePageChange(null, pageNumber + 1)}
              active={page === pageNumber + 1}
            >
              {pageNumber + 1}
            </MKPagination>
          ))}
          <MKPagination item onClick={() => handlePageChange(null, Math.min(totalPages, page + 1))}>
            <Icon>keyboard_arrow_right</Icon>
          </MKPagination>
        </MKPagination>
      </Container>
      <MKBox pt={6} px={1} sx={{ mt: 5 }}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default History;
