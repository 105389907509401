import React from "react";
import "./topEvents.scss";

function TopEvents() {
  return (
    <>
      <div className="topEvents">
        <h1>Top Events</h1>
        <div className="list">
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
          <div className="listitem">
            <div className="eventDetail">
              <img
                src="https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <div className="event">
                <span className="name">Haguruka BAL</span>
                <span className="description">African basketbal league</span>
              </div>
            </div>
            <span className="date">June 20, 2024</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopEvents;
