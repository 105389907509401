import React, { useState, useEffect } from "react";
import Axios from "utils/Axios";
import { Link, useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MKDatePicker from "components/MKDatePicker";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import the Quill stylesheet
import fileUpload from "../../../../../assets/images/file_upload.png";
import "./addEvent.scss";
import { useNavigate } from "react-router-dom";

function AddNew() {
  const { id } = useParams(); // Extract the event ID from the URL
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [eventPrice, setEventPrice] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventCover, setEventCover] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      // Fetch the event data if an ID is provided
      const getSingleEvent = () => {
        Axios.get(`?type=event&id=${id}`)
          .then((response) => {
            const data = response.data;
            setEventTitle(data.title);
            setEventDate(data.date);
            setEventPrice(data.ticketPrice);
            setEventAddress(data.location);
            setEventDescription(data.description);
            // Note: You'll need to handle the eventCover separately if you're displaying it
          })
          .catch((error) => {
            console.error("Failed to fetch event data", error);
          });
      };
      getSingleEvent();
    }
  }, [id]);

  const validateForm = () => {
    const newErrors = {};

    if (!eventTitle) newErrors.eventTitle = "Event Title is required";
    if (!eventDate) newErrors.eventDate = "Event Date is required";
    if (!eventAddress) newErrors.eventAddress = "Event Address is required";
    if (!eventCover && !id) { // Only validate cover if adding a new event
      newErrors.eventCover = "Event Cover image is required";
    } else if (eventCover && !["image/jpeg", "image/png"].includes(eventCover.type)) {
      newErrors.eventCover = "Event Cover must be a JPEG or PNG image";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateEvent = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("event_title", eventTitle);
    formData.append("event_date", eventDate);
    formData.append("event_price", eventPrice);
    formData.append("event_address", eventAddress);
    formData.append("event_description", eventDescription);
    formData.append("type", "event");
    if (eventCover) {
      formData.append("event_header_image1", eventCover);
    }

    if (id) {
      formData.append("event_id", id);
    }

    Axios.post("", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        console.log(`Event ${id ? "updated" : "created"} successfully`);
        navigate(`/pages/events/`);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mainAddNewEvent">
      <div className="AddNewEvent">
        <div>
          <div className="lg:mx-12 my-2">
            <div className="text-2xl font-bold py-0 text-primary">{id ? "Edit Event" : "New Event"}</div>
            <div className="text-sm font-light py-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </div>
          </div>
        </div>
        <div className="addNewEventText">
          <Link to="./new">
            <MKButton>
              <Icon color="inherit">aspect_ratio</Icon>
              &nbsp; All Events
            </MKButton>
          </Link>
        </div>
      </div>
      <div className="dataTable">
        <Box sx={{ width: "100%" }} mt={5}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            variant="gradient"
            bgColor="white"
            shadow="sm"
          >
            <MKBox component="section" py={0}>
              <Divider light sx={{ mx: 40, my: 2, width: "20%" }} />
              <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
                <MKBox
                  width="100%"
                  component="form"
                  role="form"
                  autoComplete="off"
                  onSubmit={handleAddOrUpdateEvent}
                >
                  <MKBox p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          color="dark"
                          label="Event Title"
                          value={eventTitle}
                          onChange={(e) => setEventTitle(e.target.value)}
                          fullWidth
                          error={!!errors.eventTitle}
                          helperText={errors.eventTitle}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKDatePicker
                          input={{ label: "Event Date" }}
                          value={eventDate}
                          onChange={(date) => {
                            const selectedDate = new Date(date);
                            const formattedDate = selectedDate.toISOString().split("T")[0];
                            setEventDate(formattedDate);
                          }}
                          fullWidth
                          error={!!errors.eventDate}
                          helperText={errors.eventDate}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          label="Event Price"
                          value={eventPrice}
                          onChange={(e) => setEventPrice(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          color="dark"
                          label="Event Address"
                          value={eventAddress}
                          onChange={(e) => setEventAddress(e.target.value)}
                          fullWidth
                          error={!!errors.eventAddress}
                          helperText={errors.eventAddress}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="inputCoverImageDiv">
                          <label htmlFor="fileInput" className="fileInputLabel">
                            <img width="20%" height="20%" src={fileUpload} alt="Upload" />
                          </label>
                          <input
                            type="file"
                            id="fileInput"
                            className="eventCoverInput"
                            onChange={(e) => setEventCover(e.target.files[0])}
                          />
                          {errors.eventCover && (
                            <div className="errorText">{errors.eventCover}</div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <ReactQuill
                          theme="snow"
                          value={eventDescription}
                          onChange={setEventDescription}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["bold", "italic", "underline"],
                              ["image", "code-block"],
                            ],
                          }}
                          placeholder="Write event description here..."
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={2}>
                      <MKButton
                        type="submit"
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} color="inherit" /> : `${id ? "Update Event" : "Post Event"}`}
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </MKBox>
          </MKBox>
        </Box>
      </div>
    </div>
  );
}

export default AddNew;
