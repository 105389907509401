import axios from 'axios';
import { useNavigate } from "react-router-dom";

// Create an Axios instance
const Axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Replace with your API base URL
});

// Add a request interceptor
Axios.interceptors.request.use(
    config => {
        // Get the token from local storage
        const token = localStorage.getItem('token');

        // If the token exists, add it to the headers
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        // Continue sending the request even if the token is not found
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
Axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const { response } = error;

        // If the status code is 401, clear local storage and redirect to login
        if (response && response.status === 401) {
            localStorage.clear();
            const navigate = useNavigate();
            navigate('/authentication/sign-in/simple');
        }

        return Promise.reject(error);
    }
);

export default Axios;