import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import "pages/Home/sections/EventCard.css";
import { useNavigate } from "react-router-dom";

const EventCard = ({ time, title, location, backgroundImage, id }) => {
  const navigate = useNavigate();

  const handleClickOpen = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  return (
    <Grid item xs={12} md={4} onClick={() => handleClickOpen(id)} style={{ cursor: "pointer" }}>
      <MKBox position="relative" borderRadius="lg" overflow="hidden" className="event-card">
        <MKBox component="img" src={backgroundImage} alt={title} className="card-image" />
        <MKBox className="card-content">
          <MKTypography variant="h3" color="white" className="event-title">
            {title}
          </MKTypography>
          <MKTypography variant="h6" color="white" className="event-location">
            {location}
          </MKTypography>
          <MKTypography variant="h6" color="white" className="event-time">
            {time}
          </MKTypography>
        </MKBox>
      </MKBox>
    </Grid>
  );
};

EventCard.propTypes = {
  id: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default EventCard;
