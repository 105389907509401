import React from "react";
import Nav from "layouts/dashboardNav";
import Footer from "layouts/dashboardFooter";
import Sidebar from "layouts/dashboardSidebar";
import Home from "./Home";
import NewEvent from "pages/Event/New/sections/NewEvent";

//--- import style ---
import "../../styles/global.scss";

function Dashboard() {
  return (
    <>
      <div className="main">
        <div className="navContainer">
          <Nav />
        </div>
        <div className="main-container">
          <div className="sidebarContainer">
            <Sidebar />
          </div>
          <div className="contentContainer">
            <Home />
          </div>
        </div>
        <div className="footerContainer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
