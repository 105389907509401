import React from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

//--- import CSS ---
import "./revenueChart.scss";
function RevenueChart() {
  const data = [
    {
      name: "Jan",
      tickets: 40,
      users: 20,
      amount: 240,
    },
    {
      name: "Feb",
      tickets: 30,
      users: 138,
      amount: 221,
    },
    {
      name: "Mar",
      tickets: 20,
      users: 98,
      amount: 300,
    },
    {
      name: "Apr",
      tickets: 80,
      users: 98,
      amount: 200,
    },
    {
      name: "May",
      tickets: 90,
      users: 48,
      amount: 210,
    },
    {
      name: "Jun",
      tickets: 23,
      users: 80,
      amount: 250,
    },
    {
      name: "Jul",
      tickets: 490,
      users: 30,
      amount: 210,
    },
  ];

  return (
    <div className="revenueChart">
      <h1>Revenue Analytics</h1>
      <div className="chart">
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="tickets" stackId="1" stroke="#8884d8" fill="#8884d8" />
            <Area type="monotone" dataKey="users" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
            <Area type="monotone" dataKey="amount" stackId="1" stroke="#ffc658" fill="#ffc658" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default RevenueChart;