import React from "react";
import Nav from "layouts/dashboardNav";
import Sidebar from "layouts/dashboardSidebar";
import Footer from "layouts/dashboardFooter";
import Events from "./events";
import Button from '@mui/material/Button';

//import global css
import "../../../styles/global.scss";

function PromoterEvents() {
  return (
    <>
      <div className="main">
        <div className="navContainer">
          <Nav />
        </div>
        <div className="main-container">
          <div className="sidebarContainer">
            <Sidebar />
          </div>
          <div className="contentContainer">
            <Events />
          </div>
        </div>
        <div className="footerContainer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default PromoterEvents;