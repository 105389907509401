import React from "react";

//--- import style ---
import "./index.scss";

function Footer() {
  return (
    <>
      <div className="footer">
        <span>HanyeLeo</span>
        <span>HanyeLeo Event Management Dashboard</span>
      </div>
    </>
  );
}

export default Footer;