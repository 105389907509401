import React from "react";
import Nav from "layouts/dashboardNav";
import Sidebar from "layouts/dashboardSidebar";
import Footer from "layouts/dashboardFooter";
import SingleEvent from "./SingleEvent";
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

function SingleEventLayout() {
  return (
    <>
      <div className="main">
        <div className="navContainer">
          <Nav />
        </div>
        <div className="main-container">
          <div className="sidebarContainer">
            <Sidebar />
          </div>
          <div className="contentContainer">
          {/* <div className="lg:mx-12 my-2">
            <div className="text-2xl font-bold py-0 text-primary">Tab Title</div>
            <div className="text-sm font-light py-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </div>
          </div> */}
            {/* <Card className="bg-gray-800">
              <CardContent>
              </CardContent>
            </Card> */}
                <SingleEvent />
          </div>
        </div>
        <div className="footerContainer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default SingleEventLayout;
