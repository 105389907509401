import React from "react";

//--- importing other components ---
import TopEvents from "components/TopEvents/TopEvents";
import ChartBox from "components/ChartBox/ChartBox";
import {
  chartBoxTotalEvents,
  chartBoxSoonEvents,
  chartBoxPastEvents,
  chartBoxFutureEvents,
  ticketRevenue,
  ticketSold,
} from "components/ChartBox/ChartBoxData";
import BarChartBox from "components/BarChart/BarChart";
import PieChartBox from "components/PieChart/PieChart";
import RevenueChart from "components/RevenueChart/RevenueChart";

//--- import style ---
import "./index.scss";

function Home() {
  return (
    <>
      <div className="home">
        <div className="box box1">
          <TopEvents />
        </div>
        <div className="box box2">
          <ChartBox {...chartBoxTotalEvents} />
        </div>
        <div className="box box3">
          {" "}
          <ChartBox {...chartBoxSoonEvents} />
        </div>
        <div className="box box4">
          <PieChartBox />
        </div>
        <div className="box box5">
          {" "}
          <ChartBox {...chartBoxFutureEvents} />
        </div>
        <div className="box box6">
          {" "}
          <ChartBox {...chartBoxPastEvents} />
        </div>
        <div className="box box7">
          <RevenueChart />
        </div>
        <div className="box box8">
          <BarChartBox {...ticketSold} />
        </div>
        <div className="box box9">
          <BarChartBox {...ticketRevenue} />
        </div>
      </div>
    </>
  );
}

export default Home;