import React from "react";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
//--- import style ---
import "./index.scss";
//--- Get our sidebar dynamic data ---
import { sidebarData } from "./sideBarData";

function Sidebar() {
  return (
    <>
      <div className="menu">
        {sidebarData.map((item) => (
          <div className="item" key={item.id}>
            <span className="title">{item.title}</span>
            {item.listItems.map((listItem) => (
              <Link to={listItem.url} className="listItem" key={listItem.id}>
                <Icon color="inherit">{listItem.icon}</Icon>
                <span className="listItemTitle">{listItem.title}</span>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default Sidebar;
