/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Events() {
  const image1 =
    "https://images.unsplash.com/photo-1563841930606-67e2bce48b78?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  const image2 =
    "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?q=80&w=1430&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  const image3 =
    "https://images.unsplash.com/photo-1525018923-1ebe8261a6a6?q=80&w=1527&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <MKBox component="section" position="relative" py={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }} pt={10}>
          <MKBox
            variant="gradient"
            sx={{
              bgcolor: "#000",
            }}
            borderRadius="lg"
            width="100%"
            pt={4}
          >
            <Grid container>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                <MKBox
                  component="img"
                  src={image3}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-10}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  Decisions: If you can&apos;t decide, the answer is no. If two equally difficult
                  paths, choose the one more painful in the short term (pain avoidance is creating
                  an illusion).
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  July 1 -{" "}
                  <MKTypography variant="caption" color="white">
                    Canal Oympia
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                  15,500 Rwf
                </MKTypography>
                <MKTypography component="p" variant="button" color="white" opacity={0.8} mb={2}>
                  Developers and Companies around the world using our products.
                </MKTypography>
                <MKTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  Get Your Ticket
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
        <Grid container item xs={10} sx={{ mx: "auto" }} pt={10}>
          <MKBox
            variant="gradient"
            sx={{
              bgcolor: "#000",
            }}
            borderRadius="lg"
            width="100%"
            pt={4}
          >
            <Grid container>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                <MKBox
                  component="img"
                  src={image1}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-10}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  Decisions: If you can&apos;t decide, the answer is no. If two equally difficult
                  paths, choose the one more painful in the short term (pain avoidance is creating
                  an illusion).
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  June 29 -{" "}
                  <MKTypography variant="caption" color="white">
                    Kigali Arena
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                  10,500 Rwf
                </MKTypography>
                <MKTypography component="p" variant="button" color="white" opacity={0.8} mb={2}>
                  Developers and Companies around the world using our products.
                </MKTypography>
                <MKTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  Get Your Ticket
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
        <Grid container item xs={10} sx={{ mx: "auto" }} pt={10}>
          <MKBox
            variant="gradient"
            sx={{
              bgcolor: "#000",
            }}
            borderRadius="lg"
            width="100%"
            pt={4}
          >
            <Grid container>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                <MKBox
                  component="img"
                  src={image2}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-10}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  Decisions: If you can&apos;t decide, the answer is no. If two equally difficult
                  paths, choose the one more painful in the short term (pain avoidance is creating
                  an illusion).
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  June 29 -{" "}
                  <MKTypography variant="caption" color="white">
                    Kigali Arena
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                  20,500 Rwf
                </MKTypography>
                <MKTypography component="p" variant="button" color="white" opacity={0.8} mb={2}>
                  Developers and Companies around the world using our products.
                </MKTypography>
                <MKTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  Get Your Ticket
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
      <Tooltip title="List More" placement="top" sx={{ cursor: "pointer" }}>
        <MKBox textAlign="center" color="dark" py={0.5} lineHeight={0}>
          <Icon sx={{ fontWeight: "bold" }} color="inherit" fontSize="default">
            keyboard_arrow_down
          </Icon>
        </MKBox>
      </Tooltip>
    </MKBox>
  );
}

export default Events;
