import React, { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKPagination from "components/MKPagination";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Rental page sections
import Search from "pages/Home/sections/Search";
import EventCard from "pages/Home/sections/EventCard";
import Testimonials from "pages/Home/sections/Testimonials";
import EventList from "pages/Home/sections/EventList";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";

// Images
import bgImage from "assets/images/event1.jpg";

import product1 from "assets/images/products/event-1.jpg";
import product2 from "assets/images/products/event-2.jpg";
import product3 from "assets/images/products/event-3.jpg";
import product4 from "assets/images/products/event-4.jpg";
import product5 from "assets/images/HanyeIcon.png"

const todayEvents = [
  {
    id: 71,
    time: "Sunday Sept 8th, 4pm - Until Late",
    title: "R&R Sundays",
    location: "Black Samurai - Nairobi",
    backgroundImage: "https://uploads.kazana.dev/event_cover_66db182cae255_IMG-20240906-WA0018.jpg",
  },
  {
    id: 71,
    time: "Sunday Sept 8th, 4pm - Until Late",
    title: "R&R Sundays",
    location: "Black Samurai - Nairobi",
    backgroundImage: "https://uploads.kazana.dev/event_cover_66db182cae255_IMG-20240906-WA0018.jpg",
  },
  {
    id: 71,
    time: "Sunday Sept 8th, 4pm - Until Late",
    title: "R&R Sundays",
    location: "Black Samurai - Nairobi",
    backgroundImage: "https://uploads.kazana.dev/event_cover_66db182cae255_IMG-20240906-WA0018.jpg",
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const cities = [
  "Nairobi",
  "Mombasa",
  "Nukuru",
  "Ruiru",
  "Eldoret",
  "Kisumu",
  "Kikuyu",
  "Ngong",
];

function Home() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchEvents = async (page = 1, limit = 10) => {
    Axios.get(`?type=event&event_time=future`)
      .then((response) => {
        const data = response.data;
        setTotalPages(data.length);
        setData(data);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };

  useEffect(() => {
    fetchEvents(page);
  }, [page, BASE_URL]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    // Logic to filter events by month
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    // Logic to filter events by city
  };

  const filteredCities = cities.filter((city) =>
    city.toLowerCase().includes(citySearch.toLowerCase())
  );

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        relative
        center
      />
      {/* <MKBox
        minHeight="70vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Book Ticket to your dream event
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur purus at arcu
              facilisis.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox> */}
      <section className="bg-gray-100">
        <Container>
          <div className="flex flex-col-reverse md:flex-row items-center justify-between py-12 md:space-x-16 px-6">
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h1 className="text-3xl md:text-5xl font-bold text-black mb-4">
                Great Events Start at <span className="text-purple-800">HanyeLeo</span>
              </h1>
              <p className="text-gray-700 mb-6">
                Welcome to HanyeLeo, bringing you the hotest events and parties in Nairobi!  
                </p>
                <p>
                Browse our event pages to see what's going on, and come back to view our image galleries of previous events (Coming Soon).
              </p>
              <br></br>
              <form className="flex items-center mt-4 justify-center md:justify-start">
                <button type="button" class="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-purple-400 font-medium rounded-lg text-sm px-16 py-3 me-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">Get Started</button>
              </form>
            </div>
            <div className="w-full md:w-1/2 flex items-center md:justify-end">
              <img src={product5} alt="Landing Image" />
            </div>
          </div>
        </Container>
      </section>

      <Container sx={{ mt: 5 }} className="pb-16">
        <div className="text-center mb-16">
          <p className="text-3xl md:text-5xl font-bold text-gray-700 mb-4">Top Events</p>
          {/* <p className="font-light text-gray-700 md:px-28 text-center mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pulvinar condimentum dictum. Donec in turpis quis magna imperdiet </p> */}
        </div>
        <Grid container spacing={3}>
          {todayEvents?.map((event) => (
            <EventCard key={event.title} {...event} />
          ))}
        </Grid>
      </Container>
      {/* <div className="text-center my-16">
        <p className="text-3xl md:text-5xl font-bold text-gray-700 mb-4">Events on HanyeLeo</p>
        <p className="font-light text-gray-700 md:px-28 text-center mb-4">Checkout these upcoming Events:</p>
      </div> */}
      {/* <Container sx={{ mt: 5 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              style={{ backgroundColor: "white", height: "43px" }}
              inputProps={{ "aria-label": "Select Month" }}
              sx={{
                mr: 2,
                minWidth: 150,
                "& .MuiSelect-select": {
                  padding: "6px 16px",
                },
                "&.MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select Month</em>
              </MenuItem>
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Select
              value={selectedCity}
              onChange={handleCityChange}
              displayEmpty
              style={{ backgroundColor: "white", height: "43px" }}
              inputProps={{ "aria-label": "Select City" }}
              sx={{
                mr: 2,
                minWidth: 150,
                "& .MuiSelect-select": {
                  padding: "6px 16px",
                },
                "&.MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Select City</em>
              </MenuItem>
              <MenuItem isReadonly>
                <TextField
                  value={citySearch}
                  onChange={(e) => setCitySearch(e.target.value)}
                  placeholder="Search City"
                  variant="outlined"
                  fullWidth
                />
              </MenuItem>
              {filteredCities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Container> */}
      <EventList events={data} />
      {/* <Container sx={{ mt: 5 }}>
        <MKPagination count={totalPages} page={page} onChange={handlePageChange}>
          <MKPagination item onClick={() => handlePageChange(null, Math.max(1, page - 1))}>
            <Icon>keyboard_arrow_left</Icon>
          </MKPagination>
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <MKPagination
              item
              key={pageNumber + 1}
              onClick={() => handlePageChange(null, pageNumber + 1)}
              active={page === pageNumber + 1}
            >
              {pageNumber + 1}
            </MKPagination>
          ))}
          <MKPagination item onClick={() => handlePageChange(null, Math.min(totalPages, page + 1))}>
            <Icon>keyboard_arrow_right</Icon>
          </MKPagination>
        </MKPagination>
      </Container> */}


      <Testimonials />

      <MKBox pt={6} px={1} sx={{ mt: 5 }}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
